




























































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { Categories } from "@/types";

const AppModule = namespace("AppModule");
const CategoriesModule = namespace("CategoriesModule");

@Component({})
export default class VMain extends Vue {
  @AppModule.Getter
  public smallScreen!: string;
  @CategoriesModule.State
  public categories!: Categories;
  async created() {
    if (this.categories.length < 1)
      await this.$store.dispatch("CategoriesModule/updateCategories");
  }
  public async onCategoryClick(id: number) {
    await this.$router.push(`/categories/${id}`);
  }
}
